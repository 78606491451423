import firebase from 'firebase'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import queryString from 'query-string'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { defaultLanguage } from '../../prismic-configuration'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { linkResolver } from '../utils/LinkResolver'

const isBrowser = typeof window !== 'undefined'

const FullPortfolio = ({ location, data }) => {
  const [status, setStatus] = useState('pending')
  const [message, setMessage] = useState('')
  const [userId, setUserId] = useState('')
  const [allUsers, setAllUsers] = useState([])
  const [formFieldEntries, setFormFieldEntries] = useState([])

  //get user id from params
  useEffect(() => {
    const params = queryString.parse(location.search)

    if (params.userId) {
      setUserId(params.userId)
    } else {
      setStatus('failed')
      setMessage('Missing needed information..')
    }
  }, [location.search])

  //fetch if exist Firebase firestore(database) data
  //get all users
  useLayoutEffect(() => {
    let isMounted = true
    if (isBrowser && isMounted) {
      try {
        firebase
          //Accesses your Firestore database
          .firestore()
          //Access the "users" collection
          .collection('usersCollection')

          .onSnapshot((snapshot) => {
            const listUsers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))

            // Now we set users equal to users we read from the Firestore

            setAllUsers(listUsers)
          })
      } catch (error) {
        console.log(error)
      }
    }
    return () => {
      isMounted = false
    }
  }, [])

  //get form field entries from user personal profile
  useEffect(() => {
    if (allUsers.length !== 0) {
      const usersPersonalData = allUsers.filter(
        (userFromList) => userFromList.id === userId,
      )[0]

      const entries = Object.entries(usersPersonalData)
      setFormFieldEntries(entries)
    }
  }, [allUsers])

  if (!data) return null

  const pageContent = data.prismicFullportfoliopage
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`
  const topMenu = data.prismicMenuLink || {}
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.full_portfolio.text} lang={lang} />
      <Container className="personal-portfolio">
        <h1 className="page-title">Full Portfolio</h1>
        {/* <span>{userId}</span> */}
        <ul className="fullPersonalPortfolio">
          {page.full_portfolio_labels.map((field, key) => {
            const fieldValueArray = formFieldEntries.filter(
              (userField) => userField[0] === field.variable_name,
            )
            let fieldValue = 'Not provided ...'
            let keyE = 'Not provided ...'

            for (const [key, value] of Object.entries(fieldValueArray)) {
              keyE = key
              fieldValue = value[1]
            }

            if (field.variable_name === 'portfolio_file') {
              return (
                <li key={key}>
                  {field.field_label} :{' '}
                  <a
                    className="text-white bg-success p-2 rounded"
                    href={fieldValue}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Document
                  </a>
                </li>
              )
            }
            return (
              <li key={key}>
                {field.field_label} :{' '}
                <span className="value border  border-1 border-info rounded-3 text-break">
                  {fieldValue}
                </span>
              </li>
            )
          })}
        </ul>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query FullportfoPageQuery($id: String, $lang: String) {
    prismicFullportfoliopage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        full_portfolio {
          text
        }
        full_portfolio_labels {
          key
          variable_name
          field_label
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(FullPortfolio, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
